import request from '@/utils/request';

export function queryTypes(data) {
  return request('/apis/citic/exam/category/searchPage', {
    data,
    method: 'post'
  });
}

export function queryAllTypes(data) {
  return request('/apis/citic/exam/category/search', {
    method: 'post',
    data
  });
}

export function getType(data) {
  return request(`/apis/citic/exam/category/findById/${data.id}`);
}

export function deleteType(data) {
  return request(`/apis/citic/exam/category/delete/${data.id}`, {
    method: 'post'
  });
}

export function queryItems(data) {
  return request('/apis/citic/exam/subject/searchPage', {
    data,
    method: 'post'
  });
}

export function queryAllItems(data) {
  return request('/apis/citic/exam/subject/search', {
    data,
    method: 'post'
  });
}

export function getItem(data) {
  return request(`/apis/citic/exam/subject/findById/${data.id}`);
}

export function getRoom(data) {
  return request(`/apis/citic/room/findById/${data.id}`);
}

export function queryDepartments(data) {
  return request('/apis/citic/department/searchPage', {
    data,
    method: 'post'
  });
}

export function queryAllDepartments(data) {
  return request('/apis/citic/department/search', {
    data,
    method: 'post'
  });
}

export function updateType(data) {
  return request(`/apis/citic/exam/category/saveOrUpdate`, {
    method: 'post',
    data
  });
}

export function updateItem(params) {
  return request(`/apis/citic/exam/subject/saveOrUpdate`, {
    method: 'post',
    data: params
  });
}


export function updateRoom(data) {
  return request(`/apis/citic/room/saveOrUpdate`, {
    method: 'post',
    data
  });
}

export function queryRooms(data) {
  return request(`/apis/citic/room/searchPage`, {
    data,
    method: 'POST'
  });
}

export function queryMerges(data) {
  return request('/apis/citic/exam/sum/up/rule/searchPage', {
    data,
    method: 'POST'
  });
}

export function getMerge(data) {
  return request(`/apis/citic/exam/sum/up/rule/findById/${data.id}`);
}

export function deleteMerge(data) {
  return request(`/apis/citic/exam/sum/up/rule/delete/${data.id}`, {
    method: 'post'
  });
}

export function saveMerge(data) {
  return request(`/apis/citic/exam/sum/up/rule/saveOrUpdate`, {
    method: 'post',
    data
  });
}

export function querySpecs(data) {
  return request('/apis/citic/adhoc/logic/searchPage', {
    data,
    method: 'post'
  });
}

export function deleteSpec(data) {
  return request(`/apis/citic/adhoc/logic/delete/${data.id}`, {
    method: 'post'
  });
}

export function saveSpec(data) {
  return request(`/apis/citic/adhoc/logic/saveOrUpdate`, {
    method: 'post',
    data
  });
}

export function getSpec(data) {
  return request(`/apis/citic/adhoc/logic/findById/${data.id}`);
}

export function queryAllSpecs(data) {
  return request('/apis/citic/adhoc/logic/search', {
    method: 'post',
    data
  });
}

export function queryAllMsgs(data) {
  return request('/apis/citic/message/template/search', {
    method: 'post',
    data
  });
}

export function queryAllLabels(data) {
  return request('/apis/citic/study/label/search', {
    method: 'post',
    data
  });
}

export function queryApplyDepartments(data) {
  return request('/apis/citic/clinic/department/search', {
    method: 'post',
    data
  });
}

export function queryApplyDoctors(data) {
  return request('/apis/citic/clinic/doctor/search', {
    method: 'post',
    data
  });
}

export function queryConfigs({ type }) {
  return request(`/apis/citic/prop/config/list/${type}`);
}


export function queryAllRooms(data) {
  return request('/apis/citic/room/search', {
    data,
    method: 'POST'
  });
}

export function queryRoomItems(data) {
  return request(`/apis/citic/room/findExamSubjects/${data.roomId}`);
}

export function queryApplyDoctorPage(data) {
  return request('/apis/citic/clinic/doctor/searchPage', {
    method: 'post',
    data
  });
}


export function queryDiagnosis(data) {
  return request('/apis/citic/clinic/diagnosis/search', {
    method: 'post',
    data
  });
}

export function queryWeixinTimes(data) {
  return request('/apis/h5Time/findWeixinTime');
}
